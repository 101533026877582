import React, { Component } from 'react'
import FooterMain from './FooterMain'
import HeaderMain from './HeaderMain'

export default class About extends Component {
  render() {
    return (
        <div>
            <HeaderMain/>
  <section className="w3l-aboutblock1 py-5" id="about">
  <div className="container py-lg-5 py-md-3">
    <div className="row">
      <div className="col-lg-6">
        <h5 className="title-small">A little about Us</h5>
        <h3 className="title-big">Welcome to Zuela</h3>
        <p className="mt-3" style={{color:"#000"}}>Zuela is a civic engagement social networking platform that is aimed at promoting good governance by providing a medium where individuals with political interests can share, engage and interact with each other or political organization and leaders on issues they feel passionate about.</p>
        <p className="mt-3" style={{color:"#000"}}>The platform focuses on major subjects; these include; elections, corruptions, politics, violence, gender and human rights. These topics are there to offer guidance and learning to the users who get engaged in Zuela. These subjects guide the users on things they are supposed to communicate and discuss on the platform.</p>
        <p className="mt-3" style={{color:"#000"}}>Zuela is aimed at building a new revolution on political interests by creating the next generation of leaders and individuals all around the world. To achieve this, we first create an interactive platform and access to tools that help individuals to become active members of their community.</p>
        <p className="mt-3" style={{color:"#000"}}>We are committed to creating a good community that is free from major issues affecting our society today. To be part of this awesome political community, join us today and let’s get started.</p>
        <a href="#MoreAboutUs" className="btn btn-primary btn-style mt-lg-5 mt-4">Learn More about Us</a>
      </div>
      <div className="col-lg-6 mt-lg-0 mt-5" style={{alignItems:"center"}}>
        <img src="assets/images/about.jpg"  className="radius-image img-fluid"  style={{height:500, width:500}}  />
      </div>
    </div>
  </div>
</section>

            <FooterMain/>
        </div>
    )
  }
}
