import React from 'react';
import Main from './Main-admin/Main'
import Users from './components/Users';
import MainApp from './Main/main-components/MainApp';
import { BrowserRouter as Router, Routes, Route }
    from "react-router-dom";
import Organization from './components/Organization';
import ApprovalRequest from './components/ApprovalRequest';
import Post from './components/Post';
import Report from './components/Report';
import About from './Main/main-components/About';
import Contact from './Main/main-components/Contact';
import Login from './Main/main-components/Login';
import Register from './Main/main-components/Register';
import RegisterUser from './Main/main-components/RegisterUser';
import RegisterOrganization from './Main/main-components/RegisterOrganization';
import ForgotPassword from './Main/main-components/ForgotPassword';
import Project from './Main/FundRaisingProjects/Project';
import ProjectDetails from './Main/FundRaisingProjects/ProjectDetails';
import Donations from './Main/FundRaisingProjects/Donations';
import CreateProject from './Main/FundRaisingProjects/CreateProject';
import CreatePoll from './Main/Polls/CreatePoll';
import Polls from './Main/Polls/Polls';
import Takepoll from './Main/Polls/Takepoll';
import Viewpoll from './Main/Polls/Viewpoll';
import Issues from './Main/Issues/Issues';
import Messages from './Main/Issues/Messages';
import Notifications from './Main/Issues/Notifications';
import Posts from './Main/Issues/posts';

function App() {
  return (
    <Router>
      
      <Routes>
        <Route path="/" element={<MainApp/>}/>
        
        <Route path="/admin" element={<Main/>}/>
        <Route path="/users" element={<Users/>}/>
        <Route path="/organization" element={<Organization/>}/>
        <Route path="/request" element={<ApprovalRequest/>}/>
        <Route path="/posts" element={<Post/>}/>
        <Route path="/report" element={<Report/>}/>

        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/userregister" element={<RegisterUser/>}/>
        <Route path="/orgregister" element={<RegisterOrganization/>}/>
        <Route path="/forgotpwd" element={<ForgotPassword/>}/>
        
        <Route path="/project" element={<Project/>}/>
        <Route path="/projectdetails" element={<ProjectDetails/>}/>
        <Route path="/donation" element={<Donations/>}/>
        <Route path="/createproj" element={<CreateProject/>}/>
        
        <Route path="/createpoll" element={<CreatePoll/>}/>
        <Route path="/poll" element={<Polls/>}/>
        <Route path="/takepoll" element={<Takepoll/>}/>
        <Route path="/viewpoll" element={<Viewpoll/>}/>

        <Route path="/issues" element={<Issues/>}/>
        <Route path="/addpost" element={<Posts/>}/>
        <Route path="/messages" element={<Messages/>}/>
        <Route path="/notifications" element={<Notifications/>}/>

      </Routes>

    </Router>
  );
}

export default App;
