import React, { useEffect,useRef,useState } from 'react'
import { Link } from 'react-router-dom'

import app from '../FundRaisingProjects/firebase';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getDatabase, ref, onValue, update, child, set} from "firebase/database";
import { getStorage , uploadBytes , ref as ref_storage, getDownloadURL} from "firebase/storage";

interface Props {
}
const HeaderMain: React.FC<Props> = ({}) => {

  const auth = getAuth(app);
  const db = getDatabase();
  const [user, setUser] =  useState<any | null>(null);
  const [uname, setUname] = useState('')
  const [uidd, setUId] = useState('')
 
  
 useEffect(() => {
      let isMounted = true;
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          setUser(user);
          setUId(uid);
          console.log(uid);
           onValue(ref(db, 'users/' + uid), (snapshot) => {
            const username = (snapshot.val() && snapshot.val().name);
            // ...
            setUname(username);
          }, {
            onlyOnce: true
          });
          // ...
        } else {
          const username = "Guest User";
          setUname(username);
          // User is signed out
          // ...
        }
      });
      return() => {isMounted = false};
    }, []);
  
    
  return (
  <div>
    <header id="site-header" className="fixed-top">
    <div className="container">
    {
    user?(
    <>
    <nav className="navbar navbar-expand-md stroke">
      <h1>
        <Link className="navbar-brand mr-md-3" to="/">
          <img src="dist/img/LOGO.JPG" className='brand-image img-circle elevation-2' alt="Your logo" title="Your logo" />
        </Link></h1>
     
      <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon fa icon-expand fa-bars" />
        <span className="navbar-toggler-icon fa icon-close fa-times " >  
        </span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul className="navbar-nav w-100">
          <li className="nav-item active">
            <Link className="nav-link" to="/issues">Home <span className="sr-only">(current)</span></Link>
          </li>
          <li className="nav-item @@about__active">
            <Link className="nav-link" to="/about">About</Link>
          </li>
          <li className="nav-item @@contact__active">
            <Link className="nav-link" to="/contact">Contact</Link>
          </li>
          <li className="ml-lg-auto mr-lg-0 m-auto">
            {/*/search-right*/}
            <div className="search-right">
              <a href="#search" title="search"><span className="fa fa-search" style={{color:"#8ec3e6"}}/></a>
              {/* search popup */}
              <div id="search" className="pop-overlay">
                <div className="popup">
                  <h4 className="mb-3">Search issue</h4>
                  <form action="error.html" method="GET" className="search-box">
                    <input type="search" placeholder="Enter Keyword" name="search" />
                    <button type="submit" className="btn btn-style btn-primary">Search</button>
                  </form>
                </div>
                <a className="close" href="#close">×</a>
              </div>
              {/* /search popup */}
            </div>
            {/*//search-right*/}
          </li>
          <li className="nav-item ">
          <Link className="nav-link" to="/issues">Welcome {uname} !!!</Link>
          </li>
          <li className="nav-item" onClick={()=>signOut(auth)} >
          <Link className="nav-link" to="/" >Logout</Link>
          </li>
        </ul>
      </div>
      
    </nav>
        </>):<>
 <nav className="navbar navbar-expand-md stroke">
 <h1><Link className="navbar-brand mr-lg-5" to="/">
     <img src="dist/img/LOGO.JPG" className='brand-image img-circle elevation-2' alt="Your logo" title="Your logo" />
   </Link></h1>

 <button className="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
   <span className="navbar-toggler-icon fa icon-expand fa-bars" />
   <span className="navbar-toggler-icon fa icon-close fa-times " >  
   </span>
 </button>
 <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
   <ul className="navbar-nav w-100">
     <li className="nav-item active">
       <Link className="nav-link" to="/issues">Home <span className="sr-only">(current)</span></Link>
     </li>
     <li className="nav-item @@about__active">
       <Link className="nav-link" to="/about">About</Link>
     </li>
     <li className="nav-item @@contact__active">
       <Link className="nav-link" to="/contact">Contact</Link>
     </li>
     <li className="ml-lg-auto mr-lg-0 m-auto">
       {/*/search-right*/}
       <div className="search-right">
         <a href="#search" title="search"><span className="fa fa-search" style={{color:"#8ec3e6"}}/></a>
         {/* search popup */}
         <div id="search" className="pop-overlay">
           <div className="popup">
             <h4 className="mb-3">Search issue</h4>
             <form action="error.html" method="GET" className="search-box">
               <input type="search" placeholder="Enter Keyword" name="search" />
               <button type="submit" className="btn btn-style btn-primary">Search</button>
             </form>
           </div>
           <a className="close" href="#close">×</a>
         </div>
         {/* /search popup */}
       </div>
       {/*//search-right*/}
     </li>
     <li className="nav-item ">
          <Link className="nav-link" to="/issues">Welcome Guest User !!!</Link>
          </li>
          <li className="nav-item" onClick={()=>signOut(auth)} >
          <Link className="nav-link" to="/" >Logout</Link>
     </li>
   </ul>
 </div>
 
</nav></>
    }
   
  </div>
</header>
 {/* bg */}
 {/* bg */}
 <div className="w3l-bg py-3 ">
    <div className="container py-lg-4 py-md-3">
      <div className="welcome-left text-center py-lg-2 py-md-3" style={{marginTop:80}}>
      <h3 className="title-big"> ZUELA</h3>
    <div className="welcome-left text-center py-lg-2 py-md-3" >
      <h2 className="title-small">Zuela is a civic engagement, social networking
               platform aimed at promoting good governance.
               It provides a medium where individuals can share,
               engage, and interact together or with political
               organizations and leaders on issues they feel
               passionate about.</h2>
                    </div>
   
      </div>
    </div>
  </div>
  {/* banner image bottom shape */}
  <div className="position-relative">
    <div className="shape overflow-hidden">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor">
        </path>
      </svg>
    </div>
  </div>
  {/* //banner image bottom shape */}
</div>
    )
  
}
export default  HeaderMain;
